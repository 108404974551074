import {Box, CssBaseline, Typography} from '@mui/material';
import styled from '@emotion/styled';

const WhiteRow = styled(Box)`
  background-color: #404040;
  min-height: 700px;
  display: flex;
  align-items: normal;
  justify-content: left;
  text-align: left;
  padding: 50px;
  background-image: url(/exclusive.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

const FooterRow = styled(Box)`
  background-color: #404040;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  text-align: center;
`;

const PageBackground = styled(Box)`
  background-color: #404040;
  min-height: 100vh;
`;

function App() {
  return (
    <PageBackground>
      <CssBaseline/>

      <WhiteRow>
        <Typography variant="h2" sx={{color: '#fff', fontWeight: 100}}>
          Exclusive Information Technology<br/>
            Services
        </Typography>
      </WhiteRow>

      <FooterRow>
        <Typography variant="body1" color="#999">
          (c) Copyright 2023
        </Typography>
        <Typography variant="body2" color="#999">
          Exclusive Information Technology Services is a non-operating company and has ceased all operations since 2014
        </Typography>
      </FooterRow>
    </PageBackground>
  );
}

export default App;
