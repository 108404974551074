import { useRouteError } from "react-router-dom";
import {Grid, Typography} from "@mui/material";

export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);

    return (
        <Grid flex={"max-content"} textAlign="center">
            <Typography variant="h1" component="h2" gutterBottom>
                OOPS!
            </Typography>
            <Typography variant="h5">
                Sorry, an unexpected error has occurred.
            </Typography>
            <Typography variant="h6">
                {error.statusText || error.message}
            </Typography>
        </Grid>
    );
}